<template>
    <div>
        <template v-if="!success">
            <ValidatedForm
                ref="form"
                class="h-full"
                :class="formClasses"
                :form-errors="formErrors"
                @submit="onSave"
            >
                <div class="flex h-full flex-col">
                    <div
                        class="flex flex-col gap-6"
                        :class="{ 'flex-grow': submitButtonOnBottom }"
                    >
                        <div class="font-bold uppercase text-white">
                            {{ heading }}
                        </div>
                        <slot />
                        <TextInput
                            v-model="email"
                            name="newsletter-email"
                            type="email"
                            label="Enter Email Address*"
                            :submitting="submitting"
                            :form-errors="formErrors"
                            container-class="text-white"
                            input-class="floating-label-grind"
                            required
                        />
                    </div>
                    <LoadingButton
                        type="submit"
                        :loading="submitting"
                        class="btn-secondary-inverse mt-6 self-stretch md:self-start"
                    >
                        {{ submitButtonLabel }}
                    </LoadingButton>
                </div>
                <template v-if="responseError">
                    <Alert type="error" class="my-4">
                        <h4>{{ responseError }}</h4>
                    </Alert>
                </template>
            </ValidatedForm>
        </template>
        <template v-else>
            <div class="text-white">
                <div class="flex items-center gap-x-4">
                    <Icon
                        name="circle-check-solid"
                        class="text-[28px] text-vibe"
                    />
                    <h3>Thank you!</h3>
                </div>
                <div class="mt-4 flex flex-col gap-8 md:pr-32">
                    <p>
                        You have successfully signed the following email address
                        up for our newsletter:
                    </p>
                    <p>{{ email }}</p>
                    <p>
                        You’ll receive emails with the latest Roastar updates!
                    </p>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import ValidatedForm from '~/components/forms/ValidatedForm.vue';
import { ref } from 'vue';
import { FormErrors } from '~/types/miscellaneous';
import TextInput from '~/components/forms/TextInput.vue';
import { useSegmentEvent } from '~/composables/useSegment';
import { sendDataLayerFormEvent } from '~/composables/useDataLayer';

const form = ref<InstanceType<typeof ValidatedForm> | null>(null);
const email = ref<string>('');
const formErrors = ref<FormErrors>({});
const submitting = ref<boolean>(false);
const success = ref<boolean>(false);
const responseError = ref(null);

const props = withDefaults(
    defineProps<{
        heading?: string;
        tag?: string;
        submitButtonLabel?: string;
        submitButtonOnBottom?: boolean;
        formClasses?: string;
    }>(),
    {
        heading: 'NEWSLETTER SIGN UP',
        tag: 'footer',
        submitButtonLabel: 'Sign up',
        submitButtonOnBottom: false,
        formClasses: '',
    },
);

const onSave = async () => {
    submitting.value = true;
    await useAuth()
        .apiRequest('POST', 'add-to-mailchimp', {
            email: email.value,
            tag: props.tag,
        })
        .then((response: any) => {
            if (response && response.status === 'success') {
                success.value = true;

                const segmentEvent = useSegmentEvent(
                    'Newsletter Sign Up',
                    'Newsletter',
                    'Newsletter Sign Up',
                );

                useHead(() => ({
                    script: segmentEvent,
                }));

                sendDataLayerFormEvent('form_submit', 'Newsletter Signup', 'newsletter-signup');
            } else {
                formErrors.value = {
                    error: 'Please contact support.',
                };
            }
            submitting.value = false;
        })
        .catch((error) => {
            formErrors.value = getFormErrors(error.data);
        })
        .finally(() => {
            submitting.value = false;
        });
};
</script>
